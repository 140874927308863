module.exports = function() {

    let Taggle = require('taggle');

    const currentTags = window.currentTags;

    $(function () {
        var taggle = new Taggle('js-taggle', {
            placeholder: '',
            tags: currentTags,
            allowDuplicates: false,
        });
    });

}();