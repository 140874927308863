module.exports = function() {

    $(function () {
        $(document).ready(function () {
            $(document).on('click', '[data-copy-text]', function (e) {
                var aux = document.createElement("input");
                aux.setAttribute("value", $(this).data('copy-text'));
                document.body.appendChild(aux);
                aux.select();
                document.execCommand("copy");
                document.body.removeChild(aux);
            });
        });
    });

}();