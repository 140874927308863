/**
 * Helper
 */
var helper = require('../../helper');

console.log('participants');

$(function() {
    var $datatable = $('#datatable-all-participants');

    $datatable.DataTable({
        "processing": true,
        "serverSide": true,
        "responsive": true,
        "lengthMenu": [ 30, 10, 25, 50, 75, 100],
        "ajax": helper.getOrigin() + "/api/participants",
        "bAutoWidth" : false,
        "language": {
            "url": helper.getOrigin() + "/vendor/datatables/Polish.json",
        },
        "order": [[ 2, "desc" ]],
        "columns": [
            {data: 'user_first_name', name: 'user_first_name'},
            {data: 'user_last_name', name: 'user_last_name'},
            {data: 'contest', name: 'contest'},
            {data: 'user_email', name: 'user_email'},
            {data: 'user_pesel', name: 'user_pesel'},
            {data: 'created_at', name: 'created_at'},
            {
                data: 'actions',
                name: 'actions',
                orderable: false,
                searchable: false,
                className : "action centerize"
            }
        ]
    });
});
