/**
 * Helper
 */
var helper = require('../../helper');

$(function() {
    var $datatable = $('#datatable-all-coaches'),
        dataTables_buttons = [
            {
                extend: 'csv',
                text: 'CSV',
                exportOptions: {
                    columns: [0, 1, 2]
                },
            },
            {
                extend: 'pdf',
                text: 'PDF',
                exportOptions: {
                    columns: [0, 1, 2]
                },
                orientation: 'landscape'
            },
            {
                extend: 'print',
                text: 'Drukuj',
                exportOptions: {
                    columns: [0, 1, 2]
                }
            },
            {
                extend: 'colvis'
            }
        ];

    $datatable.DataTable({
        "dom" : "<'row'<'col-sm-4'l><'col-sm-4 next-width-full-center 'B><'col-sm-4'f>>" +
        "<'row'<'col-sm-12'tr>>" +
        "<'row'<'col-sm-5'i><'col-sm-7'p>>",
        "processing": true,
        "serverSide": true,
        "responsive": true,
        "lengthMenu": [[ 30, 10, 25, 50, 75, 100, -1], [ 30, 10, 25, 50, 75, 100, "All"]],
        "ajax": {
            "url" : helper.getOrigin() + "/api/coaches",
            "method": 'POST',
        },
        "autoWidth" : true,
        "buttons": dataTables_buttons,
        "language": {
            "url": helper.getOrigin() + "/vendor/datatables/Polish.json",
        },
        "order": [[ 0, "asc" ]],
        "columns": [
            {data: 'name', name: 'name'},
            {data: 'date_birth', name: 'date_birth'},
            {data: 'matches_number', name: 'matches_number'},
            {
                data: 'actions',
                name: 'actions',
                orderable: false,
                searchable: false,
                className : "action centerize"
            }
        ]
    });
});
