window._ = require('lodash');
window.$ = window.jQuery = require("./../../../bower_components/jquery/dist/jquery.js");

window.CKEDITOR_BASEPATH = '/vendor/ckeditor/ckeditor/';

$.ajaxSetup({
    headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
    }
});

/* Imports */

// require('../../../bower_components/admin-lte/dist/js/adminlte.min.js');
require('../../../node_modules/admin-lte/dist/js/adminlte.min.js');
require('../../../bower_components/jquery-ui/jquery-ui.min.js');
require('../../../bower_components/bootstrap/dist/js/bootstrap.min');
// require('../../../bower_components/flipclock/compiled/flipclock.js');

require('./modules/cke.js');
require('./modules/confirm.js');
require('./modules/datetimepicker.js');
require('./modules/placeholder.js');
require('./modules/toastr.js');
require('./modules/copyText.js');


if (document.querySelector('.js-tooltip')) {
    $(function () {
        $('[data-toggle="tooltip"]').tooltip()
    })
}

if (document.querySelector('.typeahead-teams')) {
    require('./custom/typeahead_teams.js');
}

if (document.querySelector('.typeahead-games')) {
    require('./custom/typeahead_games.js');
}

if (document.querySelector('.typeahead-referees')) {
    require('./custom/typeahead_referees.js');
}

if (document.querySelector('.typeahead-news')) {
    require('./custom/typeahead_news.js');
}

if (document.querySelector('.js-statistics-dashboard')) {
    require('./modules/chartjs/statistics_in_dashboard');
}

if (document.querySelector('.js-edit-live')) {
    console.log('click edit liveeeeee')
    require('./custom/lives/edit');
}

if (document.querySelector('#js-taggle')) {
    require('./modules/taggle.js');
}

if (document.querySelector('.js-taggleMany')) {
    require('./modules/taggleMany.js');
}

if (document.querySelector('.js-gallery-main-photo')) {
    require('./modules/galleries/mainPhoto.js');
}

console.log('test js');

// if (document.querySelector('.js-calendar')) {
//     require('./custom/calendar.js');
// }

/**
 * Data tables loader
 */
require('./datatables/table-loader');
