module.exports = function () {
    $(document).ready(function () {

        /************
        * VARIABLES *
        *************/

        var helper = require('../helper');

        /********************
         * TYPEAHEAD SECTION *
         *********************/
        /* Make engine object */
        var engine = new Bloodhound({
            datumTokenizer: function (datum) {
                return Bloodhound.tokenizers.whitespace(datum.name);
            },
            queryTokenizer: Bloodhound.tokenizers.whitespace,
            remote: {
                url: helper.getOrigin() + "/api/games/search/%QUERY",
                wildcard: '%QUERY'
            },
            filter: function (response) {
                return $.map(response, function (object) {
                    return {
                        id: object.id,
                        name:  object.name,
                    };
                });
            }
        });

        /* Run TypeAhead - init function */
        $('.typeahead-games').typeahead(null, {
            minLength: 1,
            display: 'gameName',
            valueKey: 'id',
            name: 'games',
            displayKey: 'gameName',
            limit: 15,
            templates: {
                empty: ['<div class="empty-message" style="padding: 10px;"><strong>', 'Nie znaleziono rozgrywek', '</strong></div>'].join('\n'),
                suggestion: function (data) {

                    return '<p style="font-size: 100%; cursor: pointer;"><strong>' + data.gameName + '</strong></p>';
                }
            },
            source: engine
        });

        $('.typeahead-games').bind('typeahead:select', function (ev, games) {

            var $this = $(this),
                game = $this.data('field');

            var $field = $('#'+game);
            $field.val(games.id)

        });

    });
}();
