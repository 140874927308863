/**
 * Helper
 */
var helper = require('../../helper');

$(function() {
    var $datatable = $('#datatable-all-matches'),
        dataTables_buttons = [
            {
                extend: 'csv',
                text: 'CSV',
                exportOptions: {
                    columns: [0, 1, 2, 3, 4, 5, 6]
                },
            },
            {
                extend: 'pdf',
                text: 'PDF',
                exportOptions: {
                    columns: [0, 1, 2, 3, 4, 5, 6]
                },
                orientation: 'landscape'
            },
            {
                extend: 'print',
                text: 'Drukuj',
                exportOptions: {
                    columns: [0, 1, 2, 3, 4, 5, 6]
                }
            },
            {
                extend: 'colvis',
                text: 'Widoczność kolumn',
            }
        ],
        $dt;

    $dt = $datatable.DataTable({
        "dom" : "<'row'<'col-sm-4'l><'col-sm-4 next-width-full-center 'B><'col-sm-4'f>>" +
        "<'row'<'col-sm-12'tr>>" +
        "<'row'<'col-sm-5'i><'col-sm-7'p>>",
        "processing": true,
        "serverSide": true,
        "responsive": true,
        "lengthMenu": [[ 30, 10, 25, 50, 75, 100, -1], [ 30, 10, 25, 50, 75, 100, "All"]],
        "ajax": {
            "url" : helper.getOrigin() + "/api/matches",
            "method": 'POST',
            "data": function ( d ) {
                d.status_id = $('input[name="clickedStatusId"]').val();
            }
        },
        "autoWidth" : true,
        "buttons": dataTables_buttons,
        "language": {
            "url": helper.getOrigin() + "/vendor/datatables/Polish.json",
        },
        "order": [[ 3, "asc" ]],
        "columns": [
            {data: 'host', name: 'hostName'},
            {data: 'guest', name: 'guestName'},
            {data: 'score', name: 'score', searchable: false, orderable: false},
            {data: 'match_date', name: 'match_date'},
            {data: 'gameType', name: 'gameTypeName'},
            {data: 'queue', name: 'queue'},
            {data: 'season', name: 'seasonName'},
            {
                data: 'actions',
                name: 'actions',
                orderable: false,
                searchable: false,
                className : "action centerize"
            }
        ]
    });

    $('.js-overview-box').on('click', function() {
        var $this = $(this),
            $clickedStatusId = $('input[name="clickedStatusId"]'),
            statusId = $(this).data('status_id');

        $clickedStatusId.val( statusId );

        $dt.ajax.reload();
    });

});
