/**
 * Helper
 */
var helper = require('../../helper');

$(function() {
    var $datatable = $('#datatable-all-games');

    $datatable.DataTable({
        "processing": true,
        "serverSide": true,
        "responsive": true,
        "lengthMenu": [ 30, 10, 25, 50, 75, 100],
        "ajax": {
            "url" : helper.getOrigin() + "/api/games",
            "method" : 'POST'
        },
        "bAutoWidth" : false,
        "language": {
            "url": helper.getOrigin() + "/vendor/datatables/Polish.json",
        },
        "order": [[ 1, "desc" ]],
        "columns": [
            {data: 'gameTypeName', name: 'gameTypeName'},
            {data: 'seasonName', name: 'seasonName'},
            {
                data: 'actions',
                name: 'actions',
                orderable: false,
                searchable: false,
                className : "action centerize"
            }
        ]
    });
});
