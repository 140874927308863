/**
 * Helper
 */
var helper = require('../../helper');

$(function() {
    var $datatable = $('#datatable-all-teams');

    $datatable.DataTable({
        "processing": true,
        "serverSide": true,
        "responsive": true,
        "lengthMenu": [ 30, 10, 25, 50, 75, 100],
        "ajax": helper.getOrigin() + "/api/teams",
        "bAutoWidth" : false,
        "language": {
            "url": helper.getOrigin() + "/vendor/datatables/Polish.json",
        },
        "order": [[ 0, "asc" ]],
        "columns": [
            {data: 'name', name: 'name'},
            {
                data: 'actions',
                name: 'actions',
                orderable: false,
                searchable: false,
                className : "action centerize"
            }
        ]
    });
});
