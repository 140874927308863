module.exports = function() {
    // Alert when clicking btn-danger confirming if you really want to do that
    $(function() {
        $(document).ready(function(){
            $(document).on('click', '[data-confirm-action]', function(e){
                var $this = $(this),
                    msg = $this.data('confirm-action');

                if (!confirm(msg)) {
                    e.preventDefault();
                }
            });
        });
    });
}();
