var helper = require('../../helper');

$(function() {
    const $datatable = $('#datatable-photos-of-author');
    let $dt;
    const buttons = [
        {
            extend: 'csv',
            text: 'CSV',
            exportOptions: {
                columns: [0, 1, 2, 3]
            },
        },
        {
            extend: 'pdf',
            text: 'PDF',
            exportOptions: {
                columns: [0, 1, 2, 3]
            },
            orientation: 'landscape'
        },
        {
            extend: 'print',
            text: 'Drukuj',
            exportOptions: {
                columns: [0, 1, 2, 3]
            }
        },
        {
            extend: 'colvis',
            text: 'Widoczność kolumn',
        }
    ];


    $dt = $datatable.DataTable({
        "dom" : "<'row'<'col-sm-4'l><'col-sm-4'B><'col-sm-4'f>>" +
        "<'row'<'col-sm-12'tr>>" +
        "<'row'<'col-sm-5'i><'col-sm-7'p>>",
        "processing": true,
        "serverSide": true,
        "responsive": true,
        "autoWidth": true,
        "lengthMenu": [[ 20, 10, 25, 50, 75, 100, -1], [ 20, 10, 25, 50, 75, 100, "All"]],
        "ajax": {
            "url" : helper.getOrigin() + "/api/photos/of-author",
            "method": 'POST',
            "data": function ( d ) {
                d.date_from = $('input[id=date_from]').val();
                d.date_to = $('input[id=date_to]').val();
            }
        },
        "buttons": buttons,
        "language": {
            "url": helper.getOrigin() + "/vendor/datatables/Polish.json",
        },
        "order": [[ 3, "desc" ]],
        "columns": [
            {data: 'authorName', name: 'authorName'},
            {data: 'authorText', name: 'authorText'},
            {data: 'authorEmail', name: 'authorEmail'},
            {data: 'quantity', name: 'quantity'},
        ]
    });

    $('#search-form').on('submit', function(e) {
        $dt.draw();
        e.preventDefault();
    });
});
