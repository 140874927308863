module.exports = function () {
    $(document).ready(function () {
        const helper = require('../helper');
        const engine = new Bloodhound({
            datumTokenizer: function (datum) {
                return Bloodhound.tokenizers.whitespace(datum.name);
            },
            queryTokenizer: Bloodhound.tokenizers.whitespace,
            remote: {
                url: helper.getOrigin() + "/api/news/search/%QUERY",
                wildcard: '%QUERY'
            },
            filter: function (response) {
                return $.map(response, function (object) {
                    return {
                        id: object.id,
                        title:  object.title,
                    };
                });
            }
        });

        /* Run TypeAhead - init function */
        $('.typeahead-news').typeahead(null, {
            minLength: 1,
            display: 'title',
            valueKey: 'id',
            name: 'news',
            displayKey: 'title',
            limit: 15,
            templates: {
                empty: ['<div class="empty-message" style="padding: 10px;"><strong>', 'Nie znaleziono newsa', '</strong></div>'].join('\n'),
                suggestion: function (data) {

                    return '<p style="font-size: 100%; cursor: pointer;">(' + data.id + ') <strong>' + data.title + '</strong></p>';
                }
            },
            source: engine
        });


        $('.typeahead-news').bind('typeahead:select', function (ev, articles) {
            const $this = $(this);
            const news = $this.data('field');
            const $field = $('#'+news);
            $field.val(articles.id)
        });

    });
}();
