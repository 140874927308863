module.exports = function(){

    /*
     * Categories
     */
    if(document.querySelector('#datatable-all-categories')) {
        require('./categories/all.js');
    }

    /*
     * Coaches
     */
    if(document.querySelector('#datatable-all-coaches')) {
        require('./coaches/all.js');
    }

    /*
     * Contests
     */
    if(document.querySelector('#datatable-all-contests')) {
        require('./contests/index-table.js');
    }

    /*
     * Galleries
     */
    if(document.querySelector('#datatable-all-galleries')) {
        require('./galleries/all.js');
    }

    /*
     * Games
     */
    if(document.querySelector('#datatable-all-games')) {
        require('./games/all.js');
    }

    /*
     * Game Types
     */
    if(document.querySelector('#datatable-all-game-types')) {
        require('./game_types/all.js');
    }

    /*
     * Matches
     */
    if(document.querySelector('#datatable-all-matches')) {
        require('./matches/all.js');
    }

    if(document.querySelector('#datatable-all-matches-of-team')) {
        require('./matches/of-team.js');
    }

    /*
     * News
    */
    if(document.querySelector('#datatable-all-news')) {
        require('./news/all.js');
    }

    if(document.querySelector('#datatable-news-of-category')) {
        require('./news/of-category.js');
    }

    if(document.querySelector('#datatable-news-of-user')) {
        require('./news/of-user.js');
    }

    /*
     * Pages
     */
    if(document.querySelector('#datatable-all-pages')) {
        require('./pages/all.js');
    }

    /*
     * Participants
     */
    if(document.querySelector('#datatable-all-participants')) {
        require('./participants/index-table.js');
    }

    /*
     * Photos
     */
    if(document.querySelector('#datatable-all-photos')) {
        require('./photos/all.js');
    }

    if(document.querySelector('#datatable-photos-news-main-photo')) {
        require('./photos/news-main-photo.js');
    }

    if(document.querySelector('#datatable-photos-of-author')) {
        require('./photos/of-author.js');
    }

    /*
     * Players
     */
    if(document.querySelector('#datatable-all-players')) {
        require('./players/all.js');
    }

    /*
     * Referees
     */
    if(document.querySelector('#datatable-all-referees')) {
        require('./referees/all.js');
    }

    /*
     * Seasons
     */
    if(document.querySelector('#datatable-all-seasons')) {
        require('./seasons/all.js');
    }

    /*
     * Teams
     */
    if(document.querySelector('#datatable-all-teams')) {
        require('./teams/all.js');
    }

    /*
     * Users
     */
    if(document.querySelector('#datatable-all-users')) {
        require('./users/all.js');
    }

    if(document.querySelector('#datatable-users-staff')) {
        require('./users/staff.js');
    }

}();