const helper = require('../../helper');

$(function() {
    const $datatable = $('#datatable-all-news');
    let $table;
    const buttons = [
        {
            extend: 'csv',
            text: 'CSV',
            exportOptions: {
                columns: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]
            },
        },
        {
            extend: 'pdf',
            text: 'PDF',
            exportOptions: {
                columns: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]
            },
            orientation: 'landscape'
        },
        {
            extend: 'print',
            text: 'Drukuj',
            exportOptions: {
                columns: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]
            }
        },
        {
            extend: 'colvis',
            text: 'Widoczność kolumn',
        }
    ];

    $table = $datatable.DataTable({
        "dom" : "<'row'<'col-sm-4'l><'col-sm-4'B><'col-sm-4'f>>" +
          "<'row'<'col-sm-12'tr>>" +
          "<'row'<'col-sm-5'i><'col-sm-7'p>>",
        "processing": true,
        "serverSide": true,
        "responsive": true,
        "autoWidth": true,
        "lengthMenu": [[ 20, 10, 25, 50, 75, 100, -1], [ 20, 10, 25, 50, 75, 100, "All"]],
        "ajax": {
            "url" : helper.getOrigin() + "/api/news",
            "method": 'POST',
            "data": function ( d ) {
                d.tag = $('input[id=tag]').val();
                d.category_id = $('select[id=category_id]').val();
                d.date_from = $('input[id=date_from]').val();
                d.date_to = $('input[id=date_to]').val();
                d.status = $('input[name="clickedStatus"]').val();
            }
        },
        "buttons": buttons,
        "language": {
            "url": helper.getOrigin() + "/vendor/datatables/Polish.json",
        },
        "order": [[ 11, "desc" ]],
        "columns": [
            { data: 'id', name: 'id', visible: false },
            { data: 'title', name: 'title' },
            { data: 'tags', name: 'tags', orderable: false, searchable: false, visible: false },
            { data: 'author', name: 'authorName' },
            { data: 'publisher', name: 'publisherName', visible: false },
            { data: 'author_text', name: 'author_text', visible: false, searchable: false },
            { data: 'publisher_text', name: 'publisher_text', visible: false, searchable: false },
            { data: 'user', name: 'userName', visible: false },
            { data: 'category', name: 'categoryName' },
            { data: 'status', name: 'is_published', searchable: false },
            { data: 'is_featured', name: 'featured_at', searchable: false },
            { data: 'date_published', name: 'date_published' },
            {
                data: 'actions',
                name: 'actions',
                orderable: false,
                searchable: false,
                className : "action centerize"
            }
        ]
    });

    $('#search-form').on('submit', function(e) {
        $table.draw();
        e.preventDefault();
    });

    $('.js-overview-box').on('click', function() {
        const $this = $(this),
          $clickedStatus = $('input[name="clickedStatus"]'),
          status = $(this).data('status'),
          toDisplay = $(this).data('to_display');
        const activeFilter = $('#active-filter');

        $clickedStatus.val(status);
        activeFilter.text(toDisplay);

        $table.columns.adjust().draw();
        $table.ajax.reload();
    });
});
