module.exports = function() {

    require('../../../../bower_components/bootstrap-datepicker/dist/js/bootstrap-datepicker.min.js');
    require('../../../../bower_components/eonasdan-bootstrap-datetimepicker/build/js/bootstrap-datetimepicker.min.js');
    // require('../../../../bower_components/moment/min/moment.min.js');
    const moment = require("./../../../../bower_components/moment/moment.js");

    moment.updateLocale('en', {
        week: { dow: 1, doy: 6 }
    }),

    showDatePicker();
    showTimePicker();
    showDateTimePicker()

    function showDatePicker(){
        var $datePicker = $('.js-datepicker');

        $datePicker.datepicker({
            autoclose: true,
            format: "dd/mm/yyyy",
            weekStart: 1,
        });
    }

    function showTimePicker(){
        var $timePicker = $('.js-timepicker');

        $timePicker.datetimepicker({
            format: 'HH:mm'
       /*     format: 'LT'*/
        });
    }

    function showDateTimePicker(){
        var $datetimePicker = $('.js-datetimepicker');

        $datetimePicker.datetimepicker({
            
            format: 'YYYY-MM-DD HH:mm',
            // locale:  moment.locale('en', {
            //     week: { dow: 3, doy: 6 }
            // }),
            /*     format: 'LT'*/
        });
    }
    console.log('datetimepicker.js 5');

}();