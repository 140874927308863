/**
 * Helper
 */
var helper = require('../../helper');

$(function() {
    var $datatable = $('#datatable-all-pages');

    $datatable.DataTable({
        "processing": true,
        "serverSide": true,
        "responsive": true,
        "lengthMenu": [ 30, 10, 25, 50, 75, 100],
        "ajax": helper.getOrigin() + "/api/pages",
        "bAutoWidth" : false,
        "language": {
            "url": helper.getOrigin() + "/vendor/datatables/Polish.json",
            "method" : 'POST'
        },
        "order": [[ 2, "desc" ]],
        "columns": [
            {data: 'title', name: 'title'},
            {data: 'slug', name: 'slug'},
            {data: 'created_at', name: 'created_at'},
            {data: 'updated_at', name: 'updated_at'},
            {
                data: 'actions',
                name: 'actions',
                orderable: false,
                searchable: false,
                className : "action centerize"
            }
        ]
    });
});
