const helper = require('../../helper');

$(function() {
    const $datatable = $('#datatable-all-galleries');
    let $table;

    $table = $datatable.DataTable({
        "processing": true,
        "serverSide": true,
        "responsive": true,
        "lengthMenu": [[ 30, 10, 25, 50, 75, 100, -1], [ 30, 10, 25, 50, 75, 100, "All"]],
        "ajax": {
            "url" : helper.getOrigin() + "/api/galleries",
            "method": 'POST',
            "data": function ( d ) {
                d.status = $('input[name="clickedStatus"]').val();
            }
        },
        "bAutoWidth" : false,
        "language": {
            "url": helper.getOrigin() + "/vendor/datatables/Polish.json",
        },
        "order": [[ 5, "desc" ]],
        "columns": [
            { data: 'name', name: 'name' },
            { data: 'user', name: 'userName' },
            { data: 'photos_number', name: 'photos_number', searchable: false },
            { data: 'status', name: 'status', searchable: false },
            { data: 'category', name: 'categoryName' },
            { data: 'published_at', name: 'published_at' },
            {
                data: 'actions',
                name: 'actions',
                orderable: false,
                searchable: false,
                className : "action centerize"
            }
        ]
    });

    $('.js-overview-box').on('click', function() {
        const $this = $(this),
          $clickedStatus = $('input[name="clickedStatus"]'),
          status = $(this).data('status'),
          toDisplay = $(this).data('to_display');
        const activeFilter = $('#active-filter');

        console.log('clicked!', $clickedStatus, status, toDisplay);

        $clickedStatus.val(status);
        activeFilter.text(toDisplay);

        $table.columns.adjust().draw();
        $table.ajax.reload();
    });
});
