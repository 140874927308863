const helper = require('../../helper');

$(function() {
    let $datatable = $('#datatable-photos-news-main-photo'),
      $table;

    $table = $datatable.DataTable({
        "processing": true,
        "serverSide": true,
        "responsive": true,
        "lengthMenu": [ 20, 10, 50, 75, 100],
        "ajax": {
            "url" : helper.getOrigin() + "/api/photos/main",
            "method": 'POST',
            "data": function ( d ) {
                d.tag = $('input[id=tag]').val();
                d.ratio = $('select[id=ratio]').val();
                d.width = $('input[id=width_typed]').val();
                d.width_comparator = $('select[id=width_comparator]').val();
                d.height = $('input[id=height_typed]').val();
                d.height_comparator = $('select[id=height_comparator]').val();
            }
        },
        "autoWidth": true,
        "language": {
            "url": helper.getOrigin() + "/vendor/datatables/Polish.json",
        },
        "order": [[ 5, "desc" ]],
        "columns": [
            { data: 'thumbnail', name: 'thumbnail', orderable: false, searchable: false },
            { data: 'width', name: 'width' },
            { data: 'height', name: 'height' },
            { data: 'number_in_news', name: 'number_in_news', orderable: false, searchable: false },
            { data: 'last_in_news', name: 'last_in_news', orderable: false, searchable: false },
            { data: 'created_at', name: 'created_at'},
            {
                data: 'actions',
                name: 'actions',
                orderable: false,
                searchable: false,
                className : "action centerize"
            }
        ]
    });

    $('#datatable-photos-news-main-photo tbody').on( 'click', '.thumbnail', function () {
        const data = $table.row( $(this).parent().parents('tr') ).data();
        $('input[name="main_photo_id"]').val(data.id);
        $('#main-photo-thumnbail').html(data.preview);
    } );

    $('.setRatio').on('click', function(e) {
        e.preventDefault();
        const ratio = $(this).data('ratio');
        $('#ratio').val(ratio);
        $table.draw();
    });

    // $('#setNews').on('click', function(e) {
    //     e.preventDefault();
    //     $('#width').val(740);
    //     $('#height').val(360);
    //     $table.draw();
    // });

    // $('#reset').on('click', function(e) {
    //     e.preventDefault();
    //     $('#width').val(null);
    //     $('#height').val(null);
    //     $table.draw();
    // });

    $('.js-clickable-tag').on('click', function(e) {
        e.preventDefault();
        const tag = $(this).data('tag');
        $('input[id=tag]').val(tag);
        $table.draw();
    });

    $('#search-form').on('submit', function(e) {
        $table.draw();
        e.preventDefault();
    });
});
