

var toastr = require('../../../../bower_components/toastr/toastr.min.js');



var ToastrController = function() {

    // config this biatch
    toastr.options.timeOut = 10000; // How long the toast will display without user interaction
    toastr.options.extendedTimeOut = 20000; // How long the toast will display after a user hovers over it
    toastr.options.progressBar = true; // Enable progressBar on every toast

}

//ToastrController.prototype.showToastr = function(level, message, title) {
//    console.log('jest toastr');
//    switch (level) {
//        case 'success' :
//            toastr.success(message, title);
//            break;
//        case 'danger' :
//            toastr.error(message, title);
//            break;
//        case 'warning' :
//            toastr.warning(message, title);
//            break;
//        case 'info' :
//            toastr.info(message, title);
//            break;
//    }
//}

ToastrController.prototype.showSuccess = function(message, title) {
    toastr.success(message, title);
}

ToastrController.prototype.showDanger = function(message, title) {
    toastr.error(message, title);
}

ToastrController.prototype.showWarning = function(message, title) {
    toastr.warning(message, title);
}

ToastrController.prototype.showInfo = function(message, title) {
    toastr.info(message, title);
}


module.exports = ToastrController;