module.exports = function() {

    let Taggle = require('taggle');

    let tagElements = document.getElementsByClassName("js-taggleMany");
    tagElements = Array.from(tagElements);

    tagElements.map(function (tagElement) {
        $(function () {
            console.log(tagElement.dataset.tags, JSON.parse(tagElement.dataset.tags));

            const taggle = new Taggle( tagElement.id, {
                placeholder: '',
                tags: JSON.parse(tagElement.dataset.tags),
                allowDuplicates: false,
            });
        });

        tagElement.addEventListener("keydown", function(event) {
            if (event.keyCode === 13) {
             // TODO: write command
            }
        });

    });

    // $('input[type="submit"]').on('click', function(e) {
    //     e.preventDefault();
    //     console.log('clik!');
    // });

}();