module.exports = function(){
    require('../../../../public/vendor/ckeditor/ckeditor/ckeditor.js');

    if (document.querySelector('.js-ckeditor-news-excerpt')) {
        CKEDITOR.replace( 'excerpt', {
            height: 150,
        } );
    }

    if (document.querySelector('.js-ckeditor-news-content')) {
        CKEDITOR.replace( 'content', {
            height: 500,
        } );
    }

}();