module.exports = function() {

  const helper = require('../../helper');
  const ToastrController = new (require('../../services/ToastrController'));

  const galleryId = $('input[name="id"]').val();

    $('.js-gallery-main-photo').click(function(e)
    {
        const $this = $(this);

        $.ajax(
          {
              type: 'PUT',
              url: helper.getOrigin() + '/api/galleries/' + galleryId,
              data: {'main_photo_id' : $this.val()},
              dataType: 'json',

              success: function(data) {
                ToastrController.showSuccess(data.message);
              },

              error: function(data) {
                  const errors = data.responseJSON;
                  console.log(errors);
              }
          });
    });

}();