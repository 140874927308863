/**
 * Helper
 */
var helper = require('../../helper');

$(function() {
    var $datatable = $('#datatable-news-of-category'),
    modelId = $datatable.data('model'),
    $dt;

    $dt = $datatable.DataTable({
        "processing": true,
        "serverSide": true,
        "responsive": true,
        "lengthMenu": [[ 10, 25, 50, 75, 100, -1], [ 10, 25, 50, 75, 100, "All"]],
        "ajax": {
            "url" : helper.getOrigin() + "/api/news/category/"+modelId,
            "method": 'POST',
        },
        "order": [[ 1, "desc" ]],
        "columns": [
            {data: 'title', name: 'title'},
            {data: 'author', name: 'author'},
            {data: 'date_published', name: 'date_published'},
            {data: 'created_at', name: 'created_at'},
            {
                data: 'actions',
                name: 'actions',
                orderable: false,
                searchable: false,
                className : "action centerize"
            }
        ]
    });
});
