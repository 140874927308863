module.exports = function () {
    $(document).ready(function () {

        var helper = require('../../helper');

        var $editLiveBtn = $('.js-edit-live'),
            $modal = $('#edit-live');

        console.log('edit live!');

        $editLiveBtn.on('click', function(e){

            e.preventDefault();

            var $this = $(this),
                $liveId = $this.data('id'),
                $liveTime = $('#live-time-'+$liveId).html(),
                $liveDescription = $('#live-description-'+$liveId).html(),
                $form = $('#model-live-edit-form');

            console.log($liveId);
            console.log($liveTime);
            console.log($liveDescription);
            console.log($form);

            $modal.find('#field_time').val($liveTime);
            CKEDITOR.instances['field_description'].setData($liveDescription);

            $form.attr('action', helper.getOrigin() + '/nowa/lives/' + $liveId);

            $modal.modal("show");
        });

    });
}();