const helper = require('../../helper');

$(function() {
    const $datatable = $('#datatable-all-players'),
        dataTables_buttons = [
            {
                extend: 'csv',
                text: 'CSV',
                exportOptions: {
                    columns: [0, 1, 2, 3, 4, 5, 6]
                },
            },
            {
                extend: 'pdf',
                text: 'PDF',
                exportOptions: {
                    columns: [0, 1, 2, 3, 4, 5, 6]
                },
                orientation: 'landscape'
            },
            {
                extend: 'print',
                text: 'Drukuj',
                exportOptions: {
                    columns: [0, 1, 2, 3, 4, 5, 6]
                }
            },
            {
                extend: 'colvis',
                text: 'Widoczność kolumn',
            }
        ];

    const $table = $datatable.DataTable({
        "dom" : "<'row'<'col-sm-4'l><'col-sm-4 next-width-full-center 'B><'col-sm-4'f>>" +
        "<'row'<'col-sm-12'tr>>" +
        "<'row'<'col-sm-5'i><'col-sm-7'p>>",
        "processing": true,
        "serverSide": true,
        "responsive": true,
        "lengthMenu": [[ 30, 10, 25, 50, 75, 100, -1], [ 30, 10, 25, 50, 75, 100, "All"]],
        "ajax": {
            "url" : helper.getOrigin() + "/api/players",
            "method": 'POST',
            "data": function ( d ) {
                d.status = $('input[name="clickedStatus"]').val();
                d.position = $('select[id=position]').val();
            }
        },
        "autoWidth" : true,
        "buttons": dataTables_buttons,
        "language": {
            "url": helper.getOrigin() + "/vendor/datatables/Polish.json",
        },
        "order": [[ 0, "asc" ]],
        "columns": [
            {data: 'name', name: 'name'},
            // {data: 'teamName', name: 'teamName'},
            {data: 'number', name: 'number'},
            {data: 'position', name: 'position'},
            {data: 'show_in_squad', name: 'show_in_squad'},
            {data: 'contract', name: 'contract'},
            {data: 'date_birth', name: 'date_birth'},
            {data: 'nationality', name: 'nationality'},

            {
                data: 'actions',
                name: 'actions',
                orderable: false,
                searchable: false,
                className : "action centerize"
            }
        ]
    });

    $('#search-form').on('submit', function(e) {
        $table.draw();
        e.preventDefault();
    });

    $('.js-overview-box').on('click', function() {
        const $this = $(this),
          $clickedStatus = $('input[name="clickedStatus"]'),
          status = $(this).data('status'),
          toDisplay = $(this).data('to_display');
        const activeFilter = $('#active-filter');

        $clickedStatus.val(status);
        activeFilter.text(toDisplay);

        $table.columns.adjust().draw();
        $table.ajax.reload();
    });
});
