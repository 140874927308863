module.exports = function() {

    var toastr = require('../../../../bower_components/toastr/toastr.min.js'),
        $toasts = $('.toast');

    toastr.options.timeOut = 10000; // How long the toast will display without user interaction
    toastr.options.extendedTimeOut = 20000; // How long the toast will display after a user hovers over it
    toastr.options.progressBar = true; // Enable progressBar on every toast

    $toasts.each(function(){
        var level = $(this).data('toast-level'),
            title = $(this).data('toast-title'),
            message = $(this).data('toast-message');

        if (level == 'success') { toastr.success(message, title); }
        if (level == 'danger') { toastr.error(message, title); }
        if (level == 'warning') { toastr.warning(message, title); }
        if (level == 'info') { toastr.info(message, title); }
    });

}();