var helper = require('../../helper');

$(function() {
    var $datatable = $('#datatable-all-photos'),
      $dt;

    $dt = $datatable.DataTable({
        "processing": true,
        "serverSide": true,
        "responsive": true,
        "lengthMenu": [ 20, 10, 25, 50, 75, 100],
        "ajax": {
            "url" : helper.getOrigin() + "/api/photos",
            "method": 'POST',
            "data": function ( d ) {
                d.tag = $('input[id=tag]').val();
                d.ratio = $('select[id=ratio]').val();
                d.width = $('input[id=width]').val();
                d.width_comparator = $('select[id=width_comparator]').val();
                d.height = $('input[id=height]').val();
                d.height_comparator = $('select[id=height_comparator]').val();
                d.status = $('input[name="clickedStatus"]').val();
            }
        },
        "bAutoWidth" : false,
        "language": {
            "url": helper.getOrigin() + "/vendor/datatables/Polish.json",
        },
        "order": [[ 8, "desc" ]],
        "columns": [
            {data: 'thumbnail', name: 'thumbnail', orderable: false, searchable: false},
            {data: 'tags', name: 'tags', orderable: false, searchable: false, visible: false},
            {data: 'authorName', name: 'authorName'},
            {data: 'width', name: 'width'},
            {data: 'height', name: 'height'},
            {data: 'ratio', name: 'ratio'},
            {data: 'last_in_news', name: 'last_in_news', orderable: false, searchable: false},
            {data: 'number_in_news', name: 'number_in_news', orderable: false, searchable: false},
            {data: 'created_at', name: 'created_at'},
            {
                data: 'actions',
                name: 'actions',
                orderable: false,
                searchable: false,
                className : "action centerize"
            }
        ]
    });

    $('#search-form').on('submit', function(e) {
        $dt.draw();
        e.preventDefault();
    });

    // $('#setNews').on('click', function(e) {
    //     e.preventDefault();
    //     $('#width').val(740);
    //     $('#height').val(360);
    // });

    $('.setRatio').on('click', function(e) {
        e.preventDefault();
        const ratio = $(this).data('ratio');
        $('#ratio').val(ratio);
        $dt.draw();
    });

    $('.js-clickable-tag').on('click', function(e) {
        console.log('clickable tag');
        e.preventDefault();
        const tag = $(this).data('tag');
        $('input[id=tag]').val(tag);
        $dt.draw();
    });

    $('.js-overview-box').on('click', function() {
        var $this = $(this),
          $clickedStatus = $('input[name="clickedStatus"]'),
          status = $(this).data('status');

        $clickedStatus.val( status );

        $dt.ajax.reload();
    });
});
